import poyasnitelnaya_inf_k_otchetnosti_9_mes_2020 from '../../../assets/files/ru/Poyasnitelnaya_inf_k_otchetnosti_9_mes_2020.pdf';
import otchetnost_9_mes_2020 from '../../../assets/files/ru/Otchetnost_9_mes_2020.pdf';
import otchetnost_1_polugodie_2020 from '../../../assets/files/ru/Otchetnost_1_polugodie_2020.pdf';
import poyasnitelnaya_inf_k_otchetnosti_1_polugodie_2020 from '../../../assets/files/ru/Poyasnitelnaya_inf_k_otchetnosti_1_polugodie_2020.pdf';


export default {
    seo: {
      title: 'Финансовая отчетность за 2020 год | Банк 131',
      description: 'Финансовая отчетность за 2020 год'
    },
    title: 'Финансовая отчетность за 2020 год',
    content: [
         {
             label: 'Дата размещения 04.03.2021',
             title: 'Годовая бухгалтерская (финансовая) отчетность за 2020 год.pdf',
             link: '/legal/ru/Godovaya_buhgalterskaya_finansovaya_otchetnost_za_2020_god.pdf',
         },
         {
             title: 'Пояснительная информация к годовой бухгалтерской финансовой отчетности за 2020 год.pdf',
             link: '/legal/ru/Poyasnitelnaya_informaciya_k_godovoj_buhgalterskoj_finansovoj_otchetnosti_za_2020_god.pdf',
         },
         {
             title: 'Аудиторское заключение о годовой бухгалтерской финансовой отчетности.pdf',
             link: '/legal/ru/Auditorskoe_zaklyuchenie_o_godovoj_buhgalterskoj_finansovoj_otchetnosti.pdf',
         },
         {
             label: 'Дата размещения 12.11.2020',
             title: 'Промежуточная бухгалтерская (финансовая) отчетность за 9 месяцев 2020 года.pdf',
             link: otchetnost_9_mes_2020,
         },
         {
             title: 'Пояснительная информация к отчетности за 9 месяцев 2020 года.pdf',
             link: poyasnitelnaya_inf_k_otchetnosti_9_mes_2020,
         },
         {
             label: 'Дата размещения 10.08.2020',
             title: 'Промежуточная бухгалтерская (финансовая) отчетность за первое полугодие 2020.pdf',
             link: otchetnost_1_polugodie_2020,
         },
         {
             title: 'Пояснительная информация к отчетности за первое полугодие 2020.pdf',
             link: poyasnitelnaya_inf_k_otchetnosti_1_polugodie_2020,
         },
         {
             label: 'Дата размещения 18.05.2020',
             title: 'Публикуемая отчетность за первый квартал 2020.zip',
             link: '/legal/ru/ru_first_kvartal_2020.zip',
             clarification: {
                 label: 'Дата размещения 29.04.2020',
                 text: 'Дата проведения очередного (годового) общего собрания участников (дата принятия решения ' +
                     'единственного участника Общества с ограниченной ответственностью "Банк 131") — 27.04.2020.'
             }
         }
     ]
}